<template>
  <j-abm
    name="user"
    avatar="user"
    :suppliers="suppliers"
    :features="{
      search: true,
      create: true,
      update: true,
      delete: false,
    }">
    <template #row="{item}">
      <div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12 col-xl-6">
              <h5>{{ item.email }}</h5>
            </div>
            <div class="col-12 col-xl-6">
              <ul>
                <li v-for="role in item.roles" :key="role">
                  {{ role }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions="{item}">
      <!-- Edit -->
      <router-link :to="`users/${item.id}/update`">
        <a-button
          shape="circle"
          icon="setting" />
      </router-link>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import JAbm from '@/views/shared/abm/search'
export default {
  components: {
    JAbm,
  },
  data: function () {
    return {
      acl,
    }
  },
  computed: {
    suppliers() {
      return {
        search: query => lms.user.search(query),
        fetchAll: page => lms.user.fetchAll(page),
      }
    },
  },
  methods: {
    onDispatch(item) {
      this.$notification.open({
        message: item.contact.name,
        duration: 30,
        description: h => <img src={`https://chart.apis.google.com/chart?cht=qr&chs=340x340&chl=${item.code}:X`} />,
        placement: 'topRight',
      })
    },
  },
}
</script>
