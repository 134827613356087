var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('j-abm',{attrs:{"name":"user","avatar":"user","suppliers":_vm.suppliers,"features":{
    search: true,
    create: true,
    update: true,
    delete: false,
  }},scopedSlots:_vm._u([{key:"row",fn:function(ref){
  var item = ref.item;
return [_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-12 col-xl-6"},[_c('h5',[_vm._v(_vm._s(item.email))])]),_c('div',{staticClass:"col-12 col-xl-6"},[_c('ul',_vm._l((item.roles),function(role){return _c('li',{key:role},[_vm._v(" "+_vm._s(role)+" ")])}),0)])])])])]}},{key:"actions",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":("users/" + (item.id) + "/update")}},[_c('a-button',{attrs:{"shape":"circle","icon":"setting"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }